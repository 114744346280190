let baseEmojiURL = "https://cdn.jsdelivr.net/gh/iamcal/emoji-data@master/img-apple-64/"
export default {
  "[微笑]": baseEmojiURL + "1f60a.png",
  "[笑]": baseEmojiURL + "1f604.png",
  "[呲牙]": baseEmojiURL + "1f601.png",
  "[OK]": baseEmojiURL + "1f44c.png",
  "[星星眼]": baseEmojiURL + "1f929.png",
  "[哦呼]": baseEmojiURL + "1f632.png",
  "[嫌弃]": baseEmojiURL + "1f626.png",
  "[喜欢]": baseEmojiURL + "1f970.png",
  "[酸了]": baseEmojiURL + "1f34b.png",
  "[大哭]": baseEmojiURL + "1f62d.png",
  "[害羞]": baseEmojiURL + "1f633.png",
  "[无语]": baseEmojiURL + "1f613.png",
  "[疑惑]": baseEmojiURL + "1f914.png",
  "[调皮]": baseEmojiURL + "1f61d.png",
  "[笑哭]": baseEmojiURL + "1f602.png",
  "[奸笑]": baseEmojiURL + "1f913.png",
  "[偷笑]": baseEmojiURL + "1f60f.png",
  "[大笑]": baseEmojiURL + "1f60f.png",
  "[晕了]": baseEmojiURL + "1f607.png",
  "[捂脸]": baseEmojiURL + "1f92d.png",
  "[呆]": baseEmojiURL + "1f976.png",
  "[疲惫]": baseEmojiURL + "1f971.png",
  "[爱了爱了]": baseEmojiURL + "1f970.png",
  "[惊讶]": baseEmojiURL + "1f635.png",
  "[小笑]": baseEmojiURL + "1f601.png",
  "[妙啊]": baseEmojiURL + "1f63c.png",
  "[狗头]": baseEmojiURL + "1f436.png",
  "[滑稽]": baseEmojiURL + "1f92a.png",
  "[吃瓜]": baseEmojiURL + "1f349.png",
  "[加油]": baseEmojiURL + "26fd.png",
  "[点赞]": baseEmojiURL + "1f44d.png",
  "[鼓掌]": baseEmojiURL + "1f44f.png",
  "[小丑]": baseEmojiURL + "1f921.png",
  "[药丸]": baseEmojiURL + "1f48a.png",
  "[轻松]": baseEmojiURL + "1f609.png",
  "[好吃]": baseEmojiURL + "1f60b.png",
  "[大爱]": baseEmojiURL + "1f60d.png",
  "[尴尬]": baseEmojiURL + "1f610.png",
  "[难过]": baseEmojiURL + "1f61e.png",
  "[悲伤]": baseEmojiURL + "1f622.png",
  "[气愤]": baseEmojiURL + "1f624.png",
  "[害怕]": baseEmojiURL + "1f628.png",
  "[嗝屁]": baseEmojiURL + "1f635.png",
  "[猫爱]": baseEmojiURL + "1f63d.png",
  "[火箭]": baseEmojiURL + "1f680.png",
  "[生病]": baseEmojiURL + "1f910.png",
  "[机器人]": baseEmojiURL + "1f916.png",
  "[颠覆]": baseEmojiURL + "1f92f.png",
  "[牛油果]": baseEmojiURL + "1f951.png",
  "[黄瓜]": baseEmojiURL + "1f952.png",
  "[烧烤]": baseEmojiURL + "1f953.png",
  "[土豆]": baseEmojiURL + "1f954.png",
  "[萝卜]": baseEmojiURL + "1f955.png",
  "[面包]": baseEmojiURL + "1f956.png",
  "[蔬菜]": baseEmojiURL + "1f957.png",
  "[锅]": baseEmojiURL + "1f958.png",
  "[煎饼果子]": baseEmojiURL + "1f959.png",
  "[蛋]": baseEmojiURL + "1f95a.png",
  "[牛奶]": baseEmojiURL + "1f95b.png",
  "[摄像机]": baseEmojiURL + "1f3a4.png",
  "[摄影机Low]": baseEmojiURL + "1f3a6.png",
  "[耳机]": baseEmojiURL + "1f3a7.png",
  "[调色板]": baseEmojiURL + "1f3a8.png",
  "[游戏机]": baseEmojiURL + "1f3ae.png",
  "[Action]": baseEmojiURL + "1f3ac.png",
  "[Music]": baseEmojiURL + "1f3b5.png",
  "[Musics]": baseEmojiURL + "1f3b6.png",
  "[乐器-0]": baseEmojiURL + "1f3b7.png",
  "[乐器-1]": baseEmojiURL + "1f3b8.png",
  "[乐器-2]": baseEmojiURL + "1f3b9.png",
  "[乐器-3]": baseEmojiURL + "1f3ba.png",
  "[乐谱]": baseEmojiURL + "1f3bc.png",
};
